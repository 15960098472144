<!--购售电收益-->

<template>
    <widget-framework v-if="dataLoaded" title="购售电收益" :show-border="true" @click="$router.push('/business/customerAccount')">
        <template #content>
            <el-row style="height: 70%;">
                <el-col :span="24" class="profit">
                    <div class="profitContainer">
                        <span class="number">{{profit}}</span>
                        <br/>
                        <span class="name">购售电利润</span>
                    </div>
                </el-col>
            </el-row>
            <el-row class="sale" :gutter="12">
                <el-col :span="6" class="label">售电量:</el-col>
                <el-col :span="4" class="value">{{sales}}kW</el-col>
                <el-col :span="6" class="label">售电收益:</el-col>
                <el-col :span="6" class="value">{{salesIncome}}元</el-col>
            </el-row>
            <el-row class="purchase" :gutter="12">
                <el-col :span="6" class="label">购电量:</el-col>
                <el-col :span="4" class="value">{{purchase}}kW</el-col>
                <el-col :span="6" class="label">购电成本:</el-col>
                <el-col :span="6" class="value">{{purchaseCost}}元</el-col>
            </el-row>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import numeral from 'numeral';
export default {
    name: 'SumOfPurchaseAndSaleOfElectricity',
    components: {
        WidgetFramework
    },
    data() {
        return {
            dataLoaded: false,
            purchase: 0,
            purchaseCost: 0,
            sales: 0,
            salesIncome: 0
        };
    },
    computed: {
        profit() {
            return numeral(this.salesIncome || 0)
                .subtract(this.purchaseCost || 0)
                .format('0.00');
        },
    },
    mounted() {
        this.$client.sumOfPurchaseAndSale().then((ret) => {
            const {data = []} = ret;
            const profit = data.salesIncome - data.purchaseCost;
            this.profit = profit;
            this.purchase = data.purchase;
            this.purchaseCost = data.purchaseCost;
            this.sales = data.sales;
            this.salesIncome = data.salesIncome;
            this.dataLoaded = true;
        });
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.profit {
    height: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-image: url(~@/assets/images/widget/sum-of-purchase-and-sale-of-electricity.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .profitContainer {
        text-align: center;
        margin-left: -10px;
    }

    .number {
        font-size: 20px;
        font-weight: bold;
        color: @yellow;
    }
    .name {
        font-size: 14px;
    }
}

.sale, .purchase {
    height: 15%;
    font-size: 12px;
    .label {
        color: @cyan;
        text-align: right
    }
    .value {
        color: @green
    }
}

</style>
